import React from 'react';
import './Home.css';
import Hero from '../components/Hero'
import PickAChallenge from '../components/PickAChallenge'
import Footer from '../components/Footer'
import { useNavigate } from 'react-router-dom';
import ReactGA from 'react-ga4';

function Home() {
  const navigate = useNavigate();

  if (window.location.href.includes('//')) // Prevent errors while redirecting from challenges.renanoliveira.dev
  navigate(window.location.pathname.replace('//', '/'));

  ReactGA.send({
    hitType: "pageView",
    page: "/",
    title: "Home",
  })

  return (
    <div className="home">
        <Hero/>
        <PickAChallenge/>
        <Footer></Footer>
    </div>
  );
}

export default Home;