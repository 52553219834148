import React from 'react';
import './Hero.css';

function Hero() {
    const motivationalPhrases = [
        `Ready to become a ServiceNow ninja and level up your skills? 🥷 <br/>
        You're one challenge away.`,

        `Look out! ServiceNow challenges are coming in hot! 🔥 <br/>
            Are you ready to slay them?`,

        `Get your coffee ready, <br/>
            because these challenges won’t solve themselves. ☕ `,

        `Don’t just sit there! <br/>
            A ServiceNow challenge is <br/>waiting for you. 🎯`,

        `Your ServiceNow destiny is calling! <br/>
            Answer with confidence 📞 <br/>
            It’s challenge time!`,

        `Psst… You there! <br/>
            There’s a challenge with your name on it. 👀 Let’s go!`,

        `No pressure, but the fate of the ServiceNow universe <br/>
            depends on your next move 🪐 <br/>
            Are you ready?`,

        `Started from the bottom, <br/>
            now you're here! 🎤 <br/>
            Ready for a challenge?`,

        `Your skills are about to go <br/>
            from 0 to 100, real quick! 🚀 <br/>
            Buckle up for the challenge!`,

            `Challenges? Bring them on! <br/>
            You’re built for this 💪 <br/>
            Let’s show them how it’s done!`,
    ];

    return (
        <div className="hero">
            <div>
                <h1 dangerouslySetInnerHTML={{ __html: motivationalPhrases[Math.floor(Math.random() * motivationalPhrases.length)] }} ></h1>
                {/* <h1>Are you ready to level up <br/>
                your ServiceNow<br/>
                Game? 💪</h1> */}
            </div>
            <div className='links'>
                <a href="https://github.com/renanluiz" rel="noreferrer" target='_blank'>Github</a>
                <a href="https://www.linkedin.com/in/renanloliveira/" rel="noreferrer" target='_blank'>LinkedIn</a>
                <a href="mailto:contact@renanoliveira.dev" rel="noreferrer" target='_blank'>Email</a>
            </div>
        </div>
    );
}

export default Hero;