import React from 'react';
import './Footer.css';

function Footer() {
    function mobileMenu() {
        let hamburger = document.querySelector(".hamburger");
        let navMenu = document.querySelector(".nav-menu");

        hamburger.classList.toggle("active");
        navMenu.classList.toggle("active");
    }

    return (
        <footer>
            <p>Made with ❤️ by <a href="https://www.renanoliveira.dev">Renan Oliveira</a></p>
        </footer>
    );
}

export default Footer;