import { useState, useEffect } from 'react';

const API_URL = 'https://ro-challenges-backend-production.up.railway.app/getChallenges'; // Ajuste o URL conforme necessário

const useChallenges = () => {
  const [challenges, setChallenges] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);

  useEffect(() => {
    const fetchChallenges = async () => {
      try {
        const response = await fetch(API_URL);
        if (!response.ok) {
          throw new Error(`HTTP error! Status: ${response.status}`);
        }
        const data = await response.json();
        setChallenges(data);
        localStorage.setItem('challenges', JSON.stringify(data)); // Atualiza o localStorage
      } catch (error) {
        setError(error.message);
        console.error('Erro ao buscar desafios:', error);
      } finally {
        setLoading(false);
      }
    };

    fetchChallenges();
  }, []); // Dependências vazias garantem que o efeito execute apenas uma vez por montagem

  return { challenges, loading, error };
};

export default useChallenges;