import React from 'react';
import './ChallengeCard.css';

function ChallengeCard(props) {
    return (
        <a href={props.disabled === 'false' ? null : props.link}
            data-ribbon={props.disabled === false ? 'Coming soon' : props.duration}
            className="mk-wrap ChallengeCard"
            style={{background: `linear-gradient(181deg, rgb(102 102 102 / 0%) -23.13%, rgb(0 0 0 / 81%) 132.38%), url(${props.background_image})`}}>
            <h4>{props.name}</h4>
            <p>{props.description}</p>
        </a>
    );
}

export default ChallengeCard;