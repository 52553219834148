import React from 'react';
import './Header.css';

function Header() {
    function mobileMenu() {
        let hamburger = document.querySelector(".hamburger");
        let navMenu = document.querySelector(".nav-menu");
        
        hamburger.classList.toggle("active");
        navMenu.classList.toggle("active");
    }

    return (
        <header className="header">
            <nav className="navbar">
                <a href="/" className="nav-logo">Renan Oliveira.</a>
                <ul className="nav-menu">
                    <li className="nav-item">
                        <a href="/" className="nav-link">Home</a>
                    </li>
                    <li className="nav-item">
                        <a href="https://www.renanoliveira.dev" className="nav-link">About me</a>
                    </li>
                    <li className="nav-item">
                        <a href="https://www.paypal.com/donate/?hosted_button_id=34W8PVHT3SKML" className="nav-link">Support the initiative</a>
                    </li>
                </ul>
                <div className="hamburger" onClick={mobileMenu}>
                    <span className="bar"></span>
                    <span className="bar"></span>
                    <span className="bar"></span>
                </div>
            </nav>
        </header>

    );
}

export default Header;