import React, { useState, useEffect } from 'react';
import './Unsubscribe.css';
import useChallenges from '../utils/useChallenges';
import ReactGA from 'react-ga4';

function Unsubscribe() {
    const currentPath = window.location.pathname.split('/');
    const [loading, setLoading] = useState(true);
    const [student, setStudent] = useState({});
    const [submitted, setSubmitted] = useState(false);

    const [errorMessage, setErrorMessage] = useState("");

    useEffect(() => {
        loadStudentInfo();
    }, []);

    const currentUser = currentPath[currentPath.length - 1];

    async function loadStudentInfo() {
        try {
            const response = await fetch('https://ro-challenges-backend-production.up.railway.app/getStudent/' + currentUser, {
                method: 'GET',
                headers: {
                    'Content-Type': 'application/json',
                },
            });

            if (!response.ok) {
                const result = await response.json();
                setErrorMessage(result.message);
                return;
            }

            await response.json().then((resp) => {
                setStudent(resp[0]);
            });

            setLoading(false);
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrorMessage('An error occurred. Please try again.');
        }
    }

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ro-challenges-backend-production.up.railway.app/unsubscribe/' + currentUser, {
                method: 'POST',
            });

            if (!response.ok) {
                const result = await response.json();
                setErrorMessage(result.message);
                return;
            }

            const result = await response.json();
            setSubmitted(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    if (loading) return <div className="loader"></div>;

    if(student == undefined){
        return <h1>Nothing to unsubscribe from :)</h1>
    }

    ReactGA.send({
        hitType: "pageView",
        page: "/unsubscribe",
        title: "Unsubscribe " + student.name,
      })

    return (
        <>
            {(!loading && !submitted) ? <div className="subscribe">
                <h4>I'm sorry to see you leaving!</h4>
                <h4>To unsubscribe to the challenge <b>{student.challengeName}</b> click on the button bellow</h4>
                <input type="submit" value='Unsubscribe' onClick={handleSubmit}/>
            </div>
                :
                <div style={{display: "flex", justifyContent: "center", flexDirection: "column", alignItems: "center", height: "70vh"}}>
                    <div className="wrapper">
                        <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /> <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                    </div>
                    <h6 className='subscribe-success-message'>You're good to go! Your challenges should be on your inbox anytime now</h6>
                </div>
            }
        </>
    );
}

export default Unsubscribe;