import React, { useState, useEffect } from 'react';
import './Subscribe.css';
import useChallenges from '../utils/useChallenges';
import { useNavigate } from 'react-router-dom';
import ErrorPage from '../components/ErrorPage';
import ReactGA from 'react-ga4';

function Subscribe() {
    const navigate = useNavigate();

    const { challenges, loading, error } = useChallenges(); // Use o hook para obter desafios
    const currentPath = window.location.pathname;
    const [email, setEmail] = useState("");
    const [name, setName] = useState("");
    const [submitted, setSubmitted] = useState(false);
    const [errorMessage, setErrorMessage] = useState("");
    const [showLayer1, setLayer1] = useState(true);
    const [showLayer2, setLayer2] = useState(false);
    const [showLayer3, setLayer3] = useState(false);

    useEffect(() => {
        if (window.location.href.includes('//')) // Prevent errors while redirecting from challenges.renanoliveira.dev
            navigate(window.location.pathname.replace('//', '/'));

        setLayer1(true);
        setLayer2(false);
        setLayer3(false);

        setTimeout(() => {
            setLayer1(false);
            setLayer2(true);
        }, 5000);
        setTimeout(() => {
            setLayer2(false);
            setLayer3(true);
        }, 12000);
    }, []);

    function findChallengeByPath(path) {
        return challenges.find(challenge => challenge.path === path);
    }

    const currentChallenge = findChallengeByPath(currentPath);

    const handleSubmit = async (e) => {
        e.preventDefault();

        try {
            const response = await fetch('https://ro-challenges-backend-production.up.railway.app/saveStudent', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                    email: email,
                    name: name,
                    challengeId: currentChallenge._id,
                }),
            });

            if (!response.ok) {
                const result = await response.json();
                setErrorMessage(result.message);
                return;
            }

            const result = await response.json();
            setSubmitted(true);
        } catch (error) {
            console.error('Error submitting form:', error);
            setErrorMessage('An error occurred. Please try again.');
        }
    };

    if (loading) return <div className="loader"></div>;
    if (error) return <p>Error: {error}</p>;
    if (!currentChallenge) return (<ErrorPage></ErrorPage>);

    const motivationalPhrases = [
        `Let the games begin! <br>
        Time to conquer <b>${currentChallenge.name}</b> like a true champion!`,
    
        `You’ve got this! <br>
        <b>${currentChallenge.name}</b> won’t know what hit it!`,
    
        `Challenge accepted! <br>
        Time to show <b>${currentChallenge.name}</b> who’s boss!`,
    
        `Nice pick! <br>
        Prepare to dominate <b>${currentChallenge.name}</b> like a pro!`,
    
        `Are you ready? <br>
        Because <b>${currentChallenge.name}</b> sure isn’t!`,
    
        `Let’s do this! <br>
        You and <b>${currentChallenge.name}</b> have an epic showdown ahead!`,
    
        `You’re in for a wild ride! <br>
        Time to crush <b>${currentChallenge.name}</b>!`,
    
        `Great call! <br>
        Let’s make <b>${currentChallenge.name}</b> wish it never existed!`,
    
        `Suit up! <br>
        It’s time to go head-to-head with <b>${currentChallenge.name}</b>!`,
    
        `This is going to be legendary! <br>
        You’re about to ace <b>${currentChallenge.name}</b> like it’s nothing!`,
    
        `Get your game face on! <br>
        <b>${currentChallenge.name}</b> doesn’t stand a chance!`,
    
        `You chose wisely! <br>
        Now go crush <b>${currentChallenge.name}</b> like it’s your daily routine!`,
    
        `Get ready to make history! <br>
        The <b>${currentChallenge.name}</b> challenge won’t know what hit it!`,
    
        `Challenge unlocked! <br>
        Time to make <b>${currentChallenge.name}</b> your new favorite hobby!`,
    
        `Feeling brave? <br>
        Because <b>${currentChallenge.name}</b> won’t be easy... but you’ve got this!`,
    ];
    
    ReactGA.send({
        hitType: "pageView",
        page: currentPath,
        title: "Subscribe " + currentChallenge.name,
      })

    return (
        <>
            {showLayer1 ?
                <div className="subscribe">
                    <h4 dangerouslySetInnerHTML={{ __html: motivationalPhrases[Math.floor(Math.random() * motivationalPhrases.length)]}} ></h4>
                    <div className="loader"></div>
                </div>
                : null}
            {showLayer2 ?
                <div className="subscribe">
                    <h4 className="contextual_message">{currentChallenge.contextual_message}</h4>
                    <div className="loader"></div>
                </div>
                : null}

            {showLayer3 ?
                <div className="subscribe">
                    <div className='subscribe-header'>
                        <h4>{currentChallenge.name}</h4>
                        <p>Duration: {currentChallenge.duration} | Level: {currentChallenge.level}</p>
                    </div>
                    {!submitted ? <form onSubmit={handleSubmit}>
                        <div style={{ display: "flex", flexDirection: 'column', marginTop: '40px', alignItems: "center" }}>

                            <h4>How do you want to be called?</h4>
                            <span
                                style={{ marginTop: "10px" }}
                                className='subscribe-input-container'>
                                <input
                                    type="text"
                                    placeholder='Abel tuter'
                                    value={name}
                                    required
                                    onChange={e => setName(e.target.value)} />
                            </span>
                        </div>
                        <div style={{ display: "flex", flexDirection: 'column', marginTop: '40px', alignItems: "center" }}>
                            <h4>Provide your best e-mail</h4>
                            <span
                                style={{ marginTop: "10px" }}
                                className='subscribe-input-container'>
                                <input
                                    type="email"
                                    placeholder='example@gmail.com'
                                    value={email}
                                    required
                                    onChange={e => setEmail(e.target.value)} />
                            </span>
                        </div>
                        {errorMessage && <p style={{ color: 'red', fontSize: "1rem" }}>{errorMessage}</p>}
                        <input type="submit" value='Subscribe' />
                    </form>
                        : /* Show confirmation */
                        <>
                            <div className="wrapper">
                                <svg className="checkmark" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 52 52"> <circle className="checkmark__circle" cx="26" cy="26" r="25" fill="none" /> <path className="checkmark__check" fill="none" d="M14.1 27.2l7.1 7.2 16.7-16.8" /></svg>
                            </div>
                            <h6 className='subscribe-success-message'>You're good to go! Your challenges should be on your inbox anytime now</h6>
                        </>
                    }
                    <p className='subscribe-disclaimer'>Don’t worry! You’ll be able to cancel your challenge at any time</p>
                </div>
                : null}
        </>
    );
}

export default Subscribe;