import React from 'react';
import './PickAChallenge.css';
import ChallengeCard from './ChallengeCard';
import useChallenges from '../utils/useChallenges'; // Ajuste o caminho conforme necessário

function PickAChallenge() {
  const { challenges, loading, error } = useChallenges();

  if (loading) return (
    <div className="PickAChallenge">
      <h3>Pick your next challenge:</h3>
      <div style={{ height: "100px", display: "flex", justifyContent: "center", alignItems: "center", paddingBottom: "200px" }}>
        <div className="loader"></div>
      </div>
    </div>

  );
  if (error) return <p>Error: {error}</p>;

  return (
    <div className="PickAChallenge">
      <h3>Pick your next challenge:</h3>
      <div className='CardGroup'>
        {challenges.map((challenge, i) => (
          <ChallengeCard
            key={i}
            name={challenge.name}
            description={challenge.description}
            link={challenge.path}
            duration={challenge.duration}
            disabled={challenge.active}
            background_image={challenge.background_image}
          />
        ))}
      </div>
    </div>
  );
}

export default PickAChallenge;
