import React, { useState, useEffect } from 'react';
import './ErrorPage.css';

function ErrorPage() {
    return (
        <div className='error-wrapper'>
            <h1><a href="#">404</a></h1>
            <p>There's nothing here.</p>
            <div className="travolta"></div>
        </div>
    );
}

export default ErrorPage;