import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import { createRoot } from "react-dom/client";
import {
  createBrowserRouter,
  RouterProvider,
  Route,
  Link,
} from "react-router-dom";
import Home from './pages/Home'
import Subscribe from './pages/Subscribe'
import Unsubscribe from './pages/Unsubscribe'
import Header from './components/Header'
import './App.css';
import ReactGA from 'react-ga4';

const router = createBrowserRouter([
  {
    path: "/",
    element: (
        <Home />
    ),
  },
  {
    path: "//",
    element: (
        <Home />
    ),
  },
  {
    path: "/*",
    element: <Subscribe/>,
  },
  {
    path: "/unsubscribe/*",
    element: <Unsubscribe/>,
  },
]);

ReactGA.initialize('G-CWQDTP7MPD');

createRoot(document.getElementById("root")).render(
  <div className='App'>
    <div className="gradient-circle"></div>
    <Header />
    <RouterProvider router={router} />
  </div>
);